<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>ประวัติการเข้ารับการพัฒนาตนเอง </div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >
        <div align="right" >
          <button @click="is_edit = !is_edit" class="add_btn" >+</button>
        </div>
        <Transition name="form_transition">
        <v-form
            v-if="is_edit"
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-text-field
              v-model="list_developed"
              :rules="[v => !!v || 'กรุณากรอกรายการที่ได้รับการพัฒนา']"
              label="รายการที่ได้รับการพัฒนา"
              required
          ></v-text-field>

          <v-text-field
              v-model="performance"
              :rules="[v => !!v || 'กรุณากรอกสมรรถนะที่พัฒนา']"
              label="สมรรถนะที่พัฒนา"
              required
          ></v-text-field>

          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                       :rules="[v => !!v || 'กรุณากรอกเลือกวันที่เริ่มต้น']"
                       required
                      :value="start_date"
                      clearable
                      label="วันที่เริ่มต้น"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="start_date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="start_date"
                    @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col
                cols="12"
                lg="6"
            >
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                       :rules="[v => !!v || 'กรุณากรอกเลือกวันที่สิ้นสุด']"
                       required
                      :value="end_date"
                      clearable
                      label="วันที่สิ้นสุด"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="end_date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="end_date"
                    @change="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>



          <v-text-field
              v-model="hour"
              :rules="[v => !!v || 'กรุณากรอกจำนวนชั่วโมง']"
              required
              label="จำนวนชั่วโมง"

          ></v-text-field>

          <v-text-field
              v-model="department"
              :rules="[v => !!v || 'กรุณากรอกหน่วยงานที่ดำเนินการพัฒนา']"
              label="หน่วยงานที่ดำเนินการพัฒนา"
              required
          ></v-text-field>

          <v-text-field
              v-model="remark"
              :rules="[v => !!v || 'กรุณากรอกหมายเหตุ']"
              label="หมายเหตุ"
              required
          ></v-text-field>


          <div align="center" style="margin-top: 2rem" >
            <v-btn
                class="mr-4 update_btn"
                @click="validate"
            >
             {{ id != 0 ? 'แก้ไข':'เพิ่มข้อมูล' }}
            </v-btn>

            <v-btn
                class="mr-4 reset_btn"
                @click="reset"
            >
             ล้างค่า
            </v-btn>

            <v-btn
                v-if=" id != 0 "
                class="mr-4 reset_btn"
                @click="cancle"
            >
              ยกเลิกแก้ไข
            </v-btn>


          </div>

        </v-form>
        </Transition>

        <v-data-table
            :headers="headers"
            :items="resultData"
            :search="search"
            sort-by="calories"
            class="elevation-1"
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="initialize"
            >
              Reset
            </v-btn>
          </template>

        </v-data-table>

      </div>
    </div>
  </v-breadcrumbs>



</template>

<script>
import {getData,postPlanHistory} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',
    search: '',
    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'ประวัติการเข้ารับการพัฒนาตนเอง ',
        disabled: true,
        href: '/plan_history',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'รายการ', value: 'list_developed' },
      { text: 'สมรรถภาพ', value: 'performance' },
      { text: 'วันที่เริ่มต้น-สิ้นสุด', value: 'start_date' + 'end_date' },
      { text: 'จำนวนชั่วโมง', value: 'hour' },
      { text: 'หน่วยงานที่ดำเนินการพัฒนา', value: 'department' },
      { text: 'หมายเหตุ', value: 'remark' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    first_name:"",
    last_name:"",
    sex:"",
    course_select:1,
    cours_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    list_developed:"",
    performance:"",
    affiliation:"",
    start_date:"",
    end_date:"",
    hour:"",
    remark:"",


    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
    is_edit: false,
    id: 0,
    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

 async mounted () {
    this.initialize()
    await this.GetData();
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)


        switch (status){
          case 200:

            this.resultData = data.plan_history;

            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
    async Update(){

      let formData = new FormData();
      formData.append("student_id",this.users.id);
      if(this.id != 0){
        formData.append('is_edit',this.id)
      }
      formData.append("list_developed",this.list_developed);
      formData.append("performance",this.performance);
      formData.append("start_date",this.start_date);
      formData.append("end_date",this.end_date);
      formData.append("hour",this.hour);
      formData.append("department",this.department);
      formData.append("remark",this.remark);



      await postPlanHistory(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)


            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              this.reset();
              await this.GetData();
            });


            break;
          default:



            break;
        }

      })

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.Update();
      }
    },
    editItem(item){
      this.is_edit = true;
      console.log(item)
      this.id = item.id;
      this.list_developed = item.list_developed;
      this.performance = item.performance;
      this.start_date = item.start_date;
      this.end_date = item.end_date;
      this.hour = item.hour;
      this.department = item.department;
      this.remark = item.remark;
    },
    reset () {
      this.$refs.form.reset()
      this.id = 0;
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.list_check_box{
  display: flex;
  gap: 20px;
}
.add_btn{
  font-size: 1.5rem;
  background: #058efa;
  color: white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_container{
  transition: 1s;
}
.form_transition-enter-active {
  animation: form_transition 1s ;
}
.form_transition-leave-active {
  animation: form_transition 1s reverse;
}
@keyframes form_transition {
  0% {
    height: 0;
    overflow: hidden;
  }
  100% {
    height: 500px;
    overflow: hidden
  }
}
</style>
