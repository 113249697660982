<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>ระบบกรอกแบบฟอร์มอัติโนมัติงานสารบรรณ</div>
      </div>

      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <h2>บันทึกข้อความ</h2>

          <v-text-field
              v-model="government"
              :rules="[v => !!v || 'กรุณากรอกส่วนราชการ']"
              label="ส่วนราชการ"
              required
          ></v-text-field>

          <v-text-field
              v-model="at_1"
              :rules="[v => !!v || 'กรุณากรอกที่']"
              label="ที่"
              required
          ></v-text-field>

          <v-row>
            <v-col
                cols="6"
                sm="5"
            >
              <v-menu
                  ref="menu"
                  v-model="menu_1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date_1"
                      label="วันที่"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="[v => !!v || 'กรุณากรอกวันที่']"
                      required
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date_1"
                    :active-picker.sync="activePicker"

                    @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>


          <v-textarea
              outlined
              label="เรื่อง"
              v-model="topic_1"
              :rules="[v => !!v || 'กรุณากรอกเรื่อง']"
          ></v-textarea>


          <v-text-field
              v-model="invite_1"
              :rules="[v => !!v || 'กรุณากรอกที่']"
              label="เรียน"
              required
          ></v-text-field>

          <v-textarea
              outlined
              label="สิ่งที่แนบมาด้วย"
              v-model="attech_1"
              :rules="[v => !!v || 'กรุณากรอกสิ่งที่แนบมาด้วย']"
          ></v-textarea>

          <h3>จึงเรียนมาเพื่อโปรดพิจารณา</h3>

          <v-text-field
              v-model="invite"
              :rules="[v => !!v || 'กรุณากรอกชื่อ']"
              label="ชื่อ"
              required
          ></v-text-field>

          <v-text-field
              v-model="position_invite"
              :rules="[v => !!v || 'กรุณากรอกตำแหน่ง']"
              label="ตำแหน่ง"
              required
          ></v-text-field>

          <hr>


          <h2>บันทึกข้อความภายนอก</h2>

          <v-textarea
              outlined
              label="ที่อยู่"
              v-model="address_2"
              :rules="[v => !!v || 'กรุณากรอกที่อยู่']"
          ></v-textarea>

          <v-row>
            <v-col
                cols="6"
                sm="5"
            >
              <v-menu
                  ref="menu"
                  v-model="menu_2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date_2"
                      label="วันที่"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="[v => !!v || 'วันที่']"
                      required
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date_2"
                    :active-picker.sync="activePicker"

                    @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-text-field
              v-model="government"
              :rules="[v => !!v || 'กรุณากรอกที่']"
              label="ที่"
              required
          ></v-text-field>




          <v-textarea
              outlined
              label="เรื่อง"
              v-model="topic_2"
              :rules="[v => !!v || 'กรุณากรอกเรื่อง']"
          ></v-textarea>

          <v-text-field
              v-model="at_1"
              :rules="[v => !!v || 'กรุณากรอกที่']"
              label="ที่"
              required
          ></v-text-field>

          <v-textarea
              outlined
              label="สิ่งที่แนบมาด้วย"
              v-model="attech_2"
              :rules="[v => !!v || 'กรุณากรอกสิ่งที่แนบมาด้วย']"
          ></v-textarea>



          <h3>ขอแสดงความนับถือ</h3>

          <v-text-field
              v-model="receive"
              :rules="[v => !!v || 'กรุณากรอกชื่อ']"
              label="ชื่อ"
              required
          ></v-text-field>

          <v-text-field
              v-model="position_receive"
              :rules="[v => !!v || 'กรุณากรอกตำแหน่ง']"
              label="ตำแหน่ง"
              required
          ></v-text-field>


          <v-textarea
              outlined
              label="ที่อยู่ติดต่อ"
              v-model="contact"
              :rules="[v => !!v || 'กรุณากรอกที่อยู่ติดต่อ']"
          ></v-textarea>




          <div align="center" style="margin-top: 2rem" >
            <v-btn
                class="mr-4 update_btn"
                @click="validate"
            >
              บันทึก
            </v-btn>

            <v-btn
                class="mr-4 reset_btn"
                @click="reset"
            >
              ยกเลิก
            </v-btn><br>
            <br>
            <font v-if="check" style="font-size: 1rem;color: #fa0505;" >กรุณากรอกข้อมููลให้ครบ</font><br>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>
<script>
// @ is an alias to /src

import {baseUrl} from "../const/api";
import $ from "jquery";
import {getData, getDepartment, postAAR1} from "../lib/controller";
import {ResponseData} from "../lib/utility";

export default {
  name: "Home",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    checkInt:[
      value => !value || value > 0 || 'กรอกเวลาชัวเป็นตัวเลขอย่างเดียว',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',
    check:false,

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    name:"",
    school:"",
    amphur:"",
    province:"",
    class_detail:"",
    student_amount:"",
    department_select:1,
    people_amount:0,
    plan_detail:"",
    plan_design:"",
    activity_plan:"",
    work_sheet:"",
    plan_problem_fix:"",
    total_hour:"",
    departments:"",
    department_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],
    users:JSON.parse(localStorage.getItem('user')),
    time: null,
    menu2: false,
    modal2: false,
    name_visitor:[],
    type_visitor:[],

    government:"",
    contact:"",
    position_receive:"",
    position_invite:"",
    topic_1:"",
    receive:"",
    invite:"",
    invite_1:"",
    attech_2:"",
    attech_1:"",
    address_2:"",
    topic_2:"",
    invite_2:"",
    date_1:"",
    date_2:"",
    at_1:"",
    menu_1:"",
    menu_2:"",

  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  async mounted () {
    this.initialize()
    await this.GetDepartment()
    await this.GetData()
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.check = false;
        this.Update();
      }else {
        this.check = true;
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },

    async GetDepartment(){
      await getDepartment((response)=>{

        const {status, data} = ResponseData(response)

        const department = [];

        switch (status){
          case 200:
            console.log(data)


            data.forEach((result)=>{
              department.push({
                "id":result.id,
                "label":result.name,
              })
            });

            this.department_list = department;

            break;
          default:


            break;
        }

      });
    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        const name_visitor = [];
        const type_visitor = [];

        switch (status){
          case 200:
            console.log(data.aar_1)


            this.date = data.aar_1.date_activity;
            this.time = data.aar_1.time_activity;
            this.class_detail = data.aar_1.class_name;
            this.student_amount = data.aar_1.amount_student;
            this.department_select = data.aar_1.department;
            this.plan_detail = data.aar_1.plan_detail;
            this.plan_design = data.aar_1.plan_design;
            this.activity_plan = data.aar_1.activity_plan;
            this.work_sheet = data.aar_1.work_sheet;
            this.plan_problem_fix = data.aar_1.plan_problem_fix;
            this.total_hour = data.aar_1.total_hour;
            this.departments = data.aar_1.departments;




            data.visitor_aar1.forEach((result)=>{
              console.log(result)
              name_visitor.push(result.name)
              type_visitor.push(result.type)
            })

            this.people_amount = name_visitor.length;
            this.name_visitor = name_visitor;
            this.type_visitor = type_visitor;

            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
    async Update(){

      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("date_activity",this.date);
      formData.append("time_activity",this.time);
      formData.append("class_name",this.class_detail);
      formData.append("amount_student",this.student_amount);
      formData.append("department",this.department_select);
      formData.append("plan_detail",this.plan_detail);
      formData.append("plan_design",this.plan_design);
      formData.append("activity_plan",this.activity_plan);
      formData.append("work_sheet",this.work_sheet);
      formData.append("plan_problem_fix",this.plan_problem_fix);
      formData.append("departments",this.departments);
      formData.append("total_hour",this.total_hour);
      formData.append("name_visitor","-"+this.name_visitor);
      formData.append("type_visitor","-"+this.type_visitor);

      await postAAR1(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;
          default:



            break;
        }

      })

    }

  },
}
</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
